.custom-popover .ant-popover-inner {
    background-color: #f3f6fa;
    text-align: right;
    padding: 12px 16px;
}

.custom-popover .ant-popover-inner-content {
    padding: 0;
}
.custom-popover .ant-popover-inner-content p {
    color: #73747d;
    margin: 2px 0;
}

.custom-popover .ant-popover-inner-content p:hover {
    color: #464ac9;
    cursor: pointer;
}

.custom-popover .ant-popover-arrow {
    display: none;
}
@primary-color: #464ac9;@border-radius-base: 4px;@error-color: #dc5758;