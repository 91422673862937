.superScoreSection {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  .header,
  .body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    height: 40px;
    .column {
      width: 10%;
      text-align: left;
      font-family: 'Montserrat', 'NotoSansCJKsc';
    }
    .longColumn {
      width: 360px;
      flex-shrink: 0;
      font-family: 'Montserrat', 'NotoSansCJKsc';
    }
  }
  .header {
    background-color: #f3f6fa;
    .column,
    .longColumn {
      font-family: 'Montserrat', 'NotoSansCJKsc';
      color: #a9acc0;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

@primary-color: #464ac9;@border-radius-base: 4px;@error-color: #dc5758;