@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@import url('./theme.less');

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

html {
  overflow: auto;
  min-height: 100%;
  height: auto;
}

@primary-color: #464ac9;@border-radius-base: 4px;@error-color: #dc5758;